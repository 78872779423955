import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Field } from 'formik';
import Captcha from '../Captcha/Captcha';
import FriendFields from '../ReportProfile/SideBar/FriendFields';
import { Hidden } from '@mui/material';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';



export default function CommonForm({
  fields,
  onSubmit,
  options = {},
  captcha = null,
  isLoading = null,
  reset = true,
  selectedFields = null,
  customClass,
}) {
  const [agreed, setAgreed] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedServices, setSelectedServices] = useState('');
  const validationSchema = Yup.object().shape(
    fields?.reduce((schema, field) => {
      schema[field.name] = field.validation;
      return schema;
    }, {})
  );

  const [imageLoading, setImageLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState(null);


  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    // const token = await executeRecaptcha('form_submission_token');
    // console.log(token)
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);


  useEffect(() => {
    if (selectedFields && selectedFields.length > 0) {
      selectedFields.map((item) => {
        formik.setFieldValue(item?.key, item?.value);
      });
    }
  }, [selectedFields]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleSubmit(file);
    setImageLoading(true);
  };

  const handleSubmit = async (file) => {
    // if (file === null) {
    //   alert('Please Select Image');
    // } else {
    //   try {
    //     const formData = new FormData();
    //     formData.append('image', file);

    //     const apiUrl = `${process.env.API_URL}/aws/frontend-image-upload`;
    //     const headers = new Headers({
    //       foldername: 'form-attachments',
    //     });

    //     const response = await fetch(apiUrl, {
    //       method: 'POST',
    //       body: formData,
    //       headers,
    //     });

    //     if (response.status === 200) {
    //       const responseData = await response.json();
    //       formik.setFieldValue('file_name', responseData.url);
    //       setImageLoading(false);
    //       setMessage('File Uploaded Successfully');
    //     } else {
    //       throw new Error('File/Image upload failed');
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    const supportedFileTypes = [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/webp',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
  
    setMessage(null); // Clear the message before starting the upload process
  
    if (file === null) {
      alert('Please Select a File');
    } else if (!supportedFileTypes.includes(file?.type)) {
      alert('Unsupported file format. Please upload a PNG, JPG, JPEG, WEBP, PDF, or DOC file.');
      setSelectedFile(null);
      formik.setFieldValue('file_name', '');
      // Clear the file input
      document.getElementById('fileInput').value = '';
    } else {
      try {
        const formData = new FormData();
        formData.append('image', file);
  
        const apiUrl = `${process.env.API_URL}/aws/frontend-image-upload`;
        const headers = new Headers({
          foldername: 'form-attachments',
        });
  
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
          headers,
        });
  
        if (response.status === 200) {
          const responseData = await response.json();
          formik.setFieldValue('file_name', responseData.url);
          setImageLoading(false);
          setMessage('File Uploaded Successfully'); // Set success message only if upload succeeds
        } else {
          throw new Error('File/Image upload failed');
        }
      } catch (error) {
        console.error(error);
        setMessage('File/Image upload failed'); // Optionally set an error message
      }
    }
  };

  const formik = useFormik({
    initialValues: fields?.reduce((initialValues, field) => {
      initialValues[field.name] = '';
      return initialValues;
    }, {}),
    validationSchema,
    onSubmit: async(values, { resetForm }) => {
      let formData = {
        ...values
      };

      if (captcha) {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
        await executeRecaptcha('form_submission_token').then(async(token)=>{
          console.log(token);
          await getting_captcha(token)
          if (token) {
            await captcha(token);
            formData.validation_token=token
            onSubmit(formData);
            if (reset) {
              resetForm();
            }
          } else {
            setShowError(true);
          }
        });
      } else {
        onSubmit(formData);
      }
    },
  });

  const getting_captcha = async(token) => {
    captcha(token);
    setCaptchaCompleted(true);
  };

  return (
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="grid grid-cols-12 gap-4">
          {fields?.map((field) => {
            return field.type === 'textarea' ? (
              <div key={field.name} className={field.customClass}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                >
                  {field.label}
                </label>
                <textarea
                  rows={field.col_row ? field.col_row : 4}
                  type={field.type || 'text'}
                  id={field.name}
                  name={field.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[field.name]}
                  placeholder={field.placeholder || ''}
                  className={`${field.className
                      ? field.className
                      : `w-full h-full p-4 border  text-gray-900 rounded focus:outline-none shadow-sm ${formik.touched[field.name] && formik.errors[field.name]
                        ? 'border-red-500'
                        : 'focus:border-blue-500'
                      }`
                    }`}
                />
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="text-sm text-red-600 error">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ) : field.type === 'file' ? (
              <div key={field.name} className={field.customClass}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                >
                  {field.label}
                </label>
                <input
                  type="file"
                  id="fileInput"
                  name={field.name}
                  onChange={handleFileChange}
                  onBlur={formik.handleBlur}
                  className={`${field.className
                    ? field.className
                    : `w-full py-2 px-4 border  text-gray-900 rounded focus:outline-none shadow-sm ${formik.touched[field.name] && formik.errors[field.name]
                      ? 'border-red-500'
                      : 'focus:border-blue-500'
                    }`
                  }`}
                />
                {imageLoading ? (
                  <span className="text-white">Uploading please wait...</span>
                ) : (
                  <span className="text-yellow-900">{message}</span>
                )}
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="text-sm text-red-600 error">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ) : field.type === 'date' ? (
              <div key={field.name} className={field.customClass}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                >
                  {field.label}
                </label>
                <input
                  type={field.type || 'text'}
                  id={field.name}
                  name={field.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[field.name]}
                  placeholder={field.placeholder || ''}
                  className={`${field.className
                      ? field.className
                      : `w-full py-2 px-4 border  text-gray-900 rounded focus:outline-none shadow-sm ${formik.touched[field.name] && formik.errors[field.name]
                        ? 'border-red-500'
                        : 'focus:border-blue-500'
                      }`
                    }`}
                />
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="text-sm text-red-600 error">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ) : field.type === 'number' ? (
              <div key={field.name} className={field.customClass}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                >
                  {field.label}
                </label>
                <input
                  type={field.type || 'number'}
                  id={field.name}
                  name={field.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[field.name]}
                  placeholder={field.placeholder || ''}
                  className={`${field.className
                      ? field.className
                      : `w-full py-2 px-4 border  text-gray-900 rounded focus:outline-none shadow-sm ${formik.touched[field.name] && formik.errors[field.name]
                        ? 'border-red-500'
                        : 'focus:border-blue-500'
                      }`
                    }`}
                />
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="text-sm text-red-600 error">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ) : field.type === 'select' ? (
              <div key={field.name} className={field.customClass}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                >
                  {field.label}
                </label>
                <select
                  id={field.name}
                  name={field.name}
                  onChange={(e) => {
                    formik.setFieldValue(field.name, e.target.value)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values[field.name]}
                  className={`w-full h-12 px-4 border text-gray-900 rounded focus:outline-none shadow-sm ${
                    formik.touched[field.name] && formik.errors[field.name]
                      ? 'border-red-500'
                      : 'focus:border-blue-500'
                  }`}
                >
                  <option value="">{field.selectLabel ? field.selectLabel : `Choose a service`}</option>
                  {field.items?.map((item, index) => (
                    <option value={item?.key} key={index}>
                      {item?.value}
                    </option>
                  ))}
                </select>
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="text-sm text-red-600 error">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ) : field.type === 'radio' ? (
              <div className='col-span-12'>
                <div className='grid grid-cols-12 gap-4'>
                  {field.options.map((option, index) => (
                    <div key={index} className='col-span-6'>
                      <input
                        type="radio"
                        key={option.value}
                        id={`${field.name}-${option.value}`}
                        name={field.name}
                        value={option.value}
                        checked={formik.values[field.name] === option.value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="mr-2"
                      />
                      <label htmlFor={`${field.name}-${option.value}`}>
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div key={field.name} className={field.customClass}>
                <label
                  htmlFor={field.name}
                  className="block text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                >
                  {field.label}
                </label>
                <input
                  type={field.type || 'text'}
                  id={field.name}
                  name={field.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[field.name]}
                  placeholder={field.placeholder || ''}
                  className={`${field.className
                      ? field.className
                      : `w-full py-2 px-4 border  text-gray-900 rounded focus:outline-none shadow-sm ${formik.touched[field.name] && formik.errors[field.name]
                        ? 'border-red-500'
                        : 'focus:border-blue-500'
                      }`
                    }`}
                />
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="text-sm text-red-600 error">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            );
          })}
          {captcha && (
            <>
              {/* <div className="col-span-12 ml-auto mr-auto" style={{ transform: 'scale(0.88)', transformOrigin: '0 0' }}>
                <Captcha onChange={getting_captcha} />
                {captchaCompleted === false && showError && (
                  <div className="text-sm text-red-600 error">Please Complete Captcha</div>
                )}
              </div> */}
            </>
          )}
          <div className={options?.btnContainerClass ? `${options?.btnContainerClass}` : 'md:col-span-12 col-span-12'}>
            <button
              type="submit"
              disabled={isLoading ? true : false}
              className={`${options?.btnCustomClass
                  ? `${options?.btnCustomClass}`
                  : `block ${options?.width} mx-auto rounded delve-bg-primary px-8 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`
                }`}
            >
              {options?.btn_name ? options.btn_name : 'Send a Message'}
            </button>
          </div>
        </div>
      </form>
  );
}